import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useBranchContext } from '../Context/Branchapi';
import { useBranchwiseidContext } from '../Context/BranchwiseCourse';
import { useToggleContext } from '../Context/Togglemanagement';
import { Link } from 'react-router-dom';

function FAQdrodown() {

    const { branches, branchDetails, coursebybranch, loading, error, fetchAllBranchesHandler, fetchBranchByUrlHandler } =
    useBranchContext();

    const {TogglecloseHandler} = useToggleContext();
    const { branchwiseCourseidhandlerDefault  } =
    useBranchwiseidContext();

    useEffect(()=>{
       fetchAllBranchesHandler()
    },[])

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel, branchUrl) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      fetchBranchByUrlHandler(branchUrl);
    }
  };

  // Styles for the modal's content
  const style = {
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 4,
    p: 0,
  };

  const style1 = {
    width: "100%",
      p: 0,
  };

  return (
    <>
      {branches.slice(1).map((branch) => (
        <Accordion
          key={branch.branchurl}
          expanded={expanded === `panel${branch.branchurl}`}
          onChange={handleChange(`panel${branch.branchurl}`, branch.branchurl)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large" sx={{mt:0}} />}
            aria-controls={`panel${branch.branchurl}bh-content`}
            id={`panel${branch.branchurl}bh-header`}
            sx={{
                display: "flex",
                alignItems: 'center', // Align items to the top
              }}
          >
            <Typography variant="h6" className='text-danger fw-bold'>{branch.displayname}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{p:0, mt:0}}>
                 {coursebybranch.map((coursebranch, index) =>(
                   <li onClick={()=>branchwiseCourseidhandlerDefault()} key={coursebranch.branchurl}>
                   <Link to={`/course-details/${coursebranch.courseurl}`} onClick={()=>TogglecloseHandler()}>{coursebranch.coursename}</Link>
               </li>
                 ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default FAQdrodown;
