import React from 'react';
import img9 from "../../../assets/images/blog/small-post-01.jpg";
import { Link } from 'react-router-dom';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';

function PostCard({blogs, branchName}) {
    const { ScrollingopenHandler } = useScrollingManagementContext();
    let newArray = blogs.slice(0, 10);

    // Filter objects with id = 2
   let filteredArray = newArray.filter(item => item.title !== branchName);
  return (
    <>
    {filteredArray.map((post, index) => (
    <div className="latest-post d-flex flex-column" onClick={()=>ScrollingopenHandler()} key={index}>
                                                <div>
                                                <h6 className="title" style={{lineHeight:"20px"}}><Link to={`/blog/${post.url}/`}>{post.title}</Link></h6>
                                                </div>
                                                <div className='latest-post'>
                                            <div className="thumbnail" style={{width: "40%"}}>
                                                <Link to={`/blog/${post.url}/`}>
                                                    <img src={post?.pic || img9} alt="Blog Images responsive-image1" />
                                                </Link>  
                                            </div>
                                            <div className="post-content d-flex  flex-column align-items-end justify-content-between">
                                                <p className="title fs-5 me-4" style={{color:"black"}}><i className="icon-eye" style={{color:"orangered"}}></i> <b>{post.view}</b></p>
                                                <ul className="blog-meta">
                                                    <li className='text-success fs-6 custom-badge2'><i className="icon-27 fs-5 text-success"></i>{post.blogpostdate}</li>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
    ))}
    </>
  )
}

export default PostCard;