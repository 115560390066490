import React from 'react';
import img from "../../assets/images/gallery/gallery-01.jpg"

function Courselist({faculty}) {
  return (
    <> 
{faculty.map((fitem, index)=>( 
  <>
     <div className="edu-course course-style-4 course-style-14" style={{background: "rgba(0,0,0,0.05)"}} key={index}>
                          <div className="inner align-items-start">
                            <div className="mx-5 ms-0" style={{background: "none"}}>
                              <a href="#">
                                <img
                                 className='responsive-image1'
                                  src={fitem.pic}
                                  onError={(e) => {
                                    e.target.src = img;
                                }}
                                style={{width: "200px", height: "200px"}}
                                  alt="Course Meta"
                                />
                              </a>
                              {/* <div className="time-top">
                                <span className="duration">
                                  <i className="icon-61"></i>3 Weeks
                                </span>
                              </div> */}
                            </div>
                            <div className="content">
                              <div className="course-price">{fitem.facultyname}</div>
                              <h6 className="title">
                                <a href="#">
                                  {fitem.designation}
                                </a>
                              </h6>
                              {/* <div className="course-rating">
                                <div className="rating">
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                </div>
                                <span className="rating-count">
                                  (5.0 /7 Rating)
                                </span>
                              </div> */}
                                                                  <span className="text-success fs-4 px-0 py-2 pb-0" style={{backgroundColor: ""}}><span><img src='https://admin.sriramsias.com/images/batchicon.png' alt=''/></span> &nbsp;{fitem.subject}</span>
                              <p dangerouslySetInnerHTML={{ __html: fitem.details }} />
                              {/* <ul className="course-meta">
                                <li>
                                  <i className="icon-24"></i>8 Lessons
                                </li>
                                <li>
                                  <i className="icon-25"></i>20 Students
                                </li>
                              </ul> */}
                            </div>
                          </div>
                          {/* <div className="hover-content-aside">
                            <div className="content">
                              <span className="course-level">Engineering</span>
                              <h5 className="title">
                                <a href="course-details.html">
                                  Microsoft Excel - Excel from Beginner to
                                  Advanced
                                </a>
                              </h5>
                              <div className="course-rating">
                                <div className="rating">
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                </div>
                                <span className="rating-count">(5)</span>
                              </div>
                              <ul className="course-meta">
                                <li>8 Lessons</li>
                                <li>20 hrs</li>
                                <li>All Levels</li>
                              </ul>
                              <div className="course-feature">
                                <h6 className="title">What You’ll Learn?</h6>
                                <ul>
                                  <li>
                                    Learn to use Python professionally, learning
                                    both Python 2 & Python 3!
                                  </li>
                                  <li>
                                    Build 6 beautiful real-world projects for
                                    your portfolio (not boring toy{" "}
                                  </li>
                                  <li>
                                    Understand the Theory behind Vue.js and use
                                    it in Real Projects
                                  </li>
                                </ul>
                              </div>
                              <div className="button-group">
                                <a href="#" className="edu-btn btn-medium">
                                  Add to Cart
                                </a>
                                <a
                                  href="#"
                                  className="wishlist-btn btn-outline-dark"
                                >
                                  <i className="icon-22"></i>
                                </a>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        </>
                      ))}
    
    </>
  )
}

export default Courselist;