import { createContext, useState, useContext } from 'react';
import { fetchTestimonials} from '../../Config/api';

const TestimonialContext = createContext();

export function TestimonialProvider({ children }) {
    const [Testimonial, setTestimonial] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

        // Fetch Testimonial data based on URL 
        const fetchTestimonialHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchTestimonials();
              setTestimonial(data.data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 
  
    return (
      <TestimonialContext.Provider value={{ Testimonial, loading, error, fetchTestimonialHandler}}>
        {children}
      </TestimonialContext.Provider>
    );
  }
  
  export function useTestimonialContext() {
    return useContext(TestimonialContext);
  }