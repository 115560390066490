const blog_data = [
  {
    id: 1,
    img: '/assets/images/blog/blog-01.png',
    category: "SRIRAM's IAS",
    title: 'Old Rajinder Nagar',
    date: "+91 - 9811489560",
    url: "/branch/old-rajinder-Nagar",
    comment: 9,
    sm_desc: "SRIRAM's IAS TOWERS, 10-B, Pusa Road, Bada Bazaar Marg, Old Rajinder Nagar, New Delhi - 110060",
    delay:'100',
    home_1:true,
  },
  {
    id: 2,
    img: '/assets/images/blog/blog-02.png',
    category: "SRIRAM's IAS",
    title: 'Mukherjee Nagar',
    date: "+91 - 8447273027",
    url: "/branch/mukherjee-nagar",
    comment: 15,
    sm_desc: 'House No. 868, First Floor, Opposite Batra Cinema, Above ICICI Bank, Dr. Mukherjee Nagar, New Delhi - 110009',
    delay:'200',
    home_1:true,
  },
  {
    id: 3,
    img: '/assets/images/blog/blog-03.png',
    category: "SRIRAM's IAS",
    title: 'Pune',
    date: "+91 - 9689000979",
    url: "/branch/pune",
    comment: 12,
    sm_desc: 'Centre 1 - Shop No. 1 along with Office Nos. 1 & 2, ChandraShobha, Ground Floor, C.T.S. No.385, Near Modi Ganpati, Narayan Peth, PUNE - 411030',
    delay:'300',
    home_1:true,
  },
  {
    id: 3,
    img: '/assets/images/blog/blog-03.png',
    category: "SRIRAM's IAS",
    title: 'Hyderabad',
    date: "+91 - 9689000979",
    url: "/branch/hyderabad",
    comment: 12,
    sm_desc: 'Centre 1 - Shop No. 1 along with Office Nos. 1 & 2, ChandraShobha, Ground Floor, C.T.S. No.385, Near Modi Ganpati, Narayan Peth, PUNE - 411030',
    delay:'300',
    home_1:true,
  }
]

export default blog_data;