import { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundContext = createContext();

export function NotFoundProvider({ children }) {
    const [ispage, setIspage] = useState(200);
    const navigate = useNavigate();

    const pagefoundhandler = (ispagevalue) =>{
        setIspage(ispagevalue)

        if(ispagevalue === 404){
             navigate("/error")
        }
    }    
  
    return (
      <NotFoundContext.Provider value={{ ispage, pagefoundhandler }}>
        {children}
      </NotFoundContext.Provider>
    );
  }
  
  export function useNotFoundContext() {
    return useContext(NotFoundContext);
  }