import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SingleNabar2 from '../SingleNabar/SingleNabar2';
import { useNavContext } from '../../Context/Navbarapi';
import { useToggleContext } from '../../Context/Togglemanagement';
import { useValidateUrlContext } from '../../Context/Validateurl';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';

function MainMenu() {
 
    const { Nav , loading, error, fetchAllNavHandler  } = useNavContext();
    const {ToggleopenHandler} = useToggleContext();
    const { validateURL } = useValidateUrlContext();
    const { ScrollingopenHandler } = useScrollingManagementContext();

    useEffect(()=>{
        fetchAllNavHandler()
    },[])

  return (
    <ul className="mainmenu">
      {Nav.map((menu, i) => {
          return (
              <li
                  key={i}
                  onClick={()=>ScrollingopenHandler()}
                  onMouseOver={()=>ToggleopenHandler()}
                  className={menu.isdropdown === "true" ? 'has-droupdown' : ''}
                  style={{
                    fontFamily: "Helvetica",
                    border: "1px solid #006CD9", // Border for the box
                    height: "35px", // Fix the height of the box
                    display: "flex", // Flexbox for proper alignment
                    alignItems: "center", // Vertically center text and icon
                    justifyContent: "space-between", // Space between text and icon
                    paddingBottom: "3px", // Padding inside the box
                    boxSizing: "border-box", // Include padding in the total height
                    marginRight: "10px", // Add spacing between list items
                    borderRadius: "2px",
                  }}

                  >

<Link 
  to={validateURL(menu.linkurl) || menu.linkurl === "#" ? `${menu.linkurl}` : `/${menu.linkurl}`} 
  style={{ display: 'inline-flex', alignItems: 'center' }} // Flexbox container
>
  {menu.icon && (
    <img 
      src={`${menu.icon}`} 
      alt={menu.title} 
      style={{ width: "10px", height: "10px", marginRight: "3px", verticalAlign: 'middle' }} // Adjust image size and alignment
    />
  )}
  {menu.title} {menu.isdropdown === "true"  && (<span style={{ marginLeft: "3px" }}></span>)}
  </Link>

                  
                  {/* <Link to={menu.linkurl !== "#" ? `/${menu.linkurl}` : `${menu.linkurl}` }>
                   {menu.icon ?  <span className="position-absolute"><img src={`${menu.icon}`} alt={menu.title}/></span> : ""}  {menu.title}
                  </Link> */}
                  {/* Render the appropriate component based on the mega_menu type */}
                  {menu.isdropdown === "true" && <>
                    <SingleNabar2 submenu={menu.dropdown} title={menu.thisid} />
                  </>}
              </li>
          );
      })}
    </ul>
  );
}

export default MainMenu;
