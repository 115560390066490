import React from 'react';
import pre from "../../assets/images/logo/sriramias.jpeg";
// import "./Loader.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGuitar } from '@fortawesome/free-solid-svg-icons';

const PreLoader = () => {
    return (
        <div className='loading'>
            <div>
              {/* <div> */}

              {/* </div> */}
            </div>
                <img src={pre} alt=''/>
            {/* <i className='icon-5'></i> */}
            {/* <FontAwesomeIcon className='icon' icon={faGuitar} /> */}
        </div>
    );
};
export default PreLoader;