import { createContext, useState, useContext } from 'react';
import SaleModal from '../ModalComponent/SaleModal';

const SaleModalContext = createContext();

export function SaleModalProvider({ children }) {
    const [Saleopen, setSaleopen] = useState(false);

      const handleSaleOpen = () => setSaleopen(true);
      const handleSaleClose = () => setSaleopen(false); 
  
    return (
      <SaleModalContext.Provider value={{ Saleopen, handleSaleOpen, handleSaleClose}}>
        {children}
        <SaleModal
        handleClose={handleSaleClose}
        open={Saleopen}
        setOpen={setSaleopen}
      />
      </SaleModalContext.Provider>
    );
  } 
  
  export function useSaleModalContext() {
    return useContext(SaleModalContext);
  }