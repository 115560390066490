import React from 'react';
import turl from "../../assets/images/team/team-01/team-03.png" 
import { Link } from 'react-router-dom';

function Team1({ instructor, image_location_path='01' }) {
  return (
    <>
                        <div className="edu-team-grid team-style-2">
                            <div className="inner">
                                <div className="thumbnail-wrap">
                                    {/* <div className="thumbnail thumbnail-custom"> */}
                                    <div className="thumbnail">
                                        <Link to="#">
                                            <img src={instructor.pic} alt="team images"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="content">
                                    {instructor.facultyname && <h6 className="title px-0"><a href="#">{instructor.facultyname}</a></h6>}
                                    {instructor.name && <h6 className="title px-0"><a href="#">{instructor.name}</a></h6>}
                                    <span className="designation" style={{minHeight: "40px", marginBottom: "0px"}}>{instructor.designation}</span>
                                    {/* <p>Consectetur adipisicing elit, sed do eius mod tempor incididunt</p> */}
                                </div>
                            </div>
                        </div>
    </>
  )
}

export default Team1;