import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SingleNabar3 from '../SingleNabar/SingleNabar3';
import { useNavContext } from '../../Context/Navbarapi';
import { useToggleContext } from '../../Context/Togglemanagement';
import { useValidateUrlContext } from '../../Context/Validateurl';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';

function MainMenu1() {
 
    const { Nav , loading, error, fetchAllNavHandler  } = useNavContext();
    const {isVisible, ToggleopenHandler} = useToggleContext();
    const { validateURL } = useValidateUrlContext();
    const { ScrollingopenHandler } = useScrollingManagementContext();

    useEffect(()=>{
        fetchAllNavHandler()
    },[])

  return (
    <>
    <ul className="mainmenu">
      {Nav.map((menu, i) => {
          return (
              <li
                  key={i}
                  onClick={()=>ScrollingopenHandler()}
                  
                  className={menu.isdropdown === "true" ? 'has-droupdown' : ''}
                  >

<Link 
// onClick={()=>ToggleopenHandler()}
  to={validateURL(menu.linkurl) || menu.linkurl === "#" ? `${menu.linkurl}` : `/${menu.linkurl}`} 
  // style={{ display: 'inline-flex', alignItems: 'center' }} // Flexbox container
>
  {menu.icon && (
    <img 
      src={`${menu.icon}`} 
      alt={menu.title} 
      style={{ width: "10px", height: "10px", marginRight: "3px", verticalAlign: 'middle' }} // Adjust image size and alignment
    />
  )}
  {menu.title} {menu.isdropdown === "true"  && (<span style={{ marginLeft: "3px" }}></span>)}
  </Link>

                  
                  {/* <Link to={menu.linkurl !== "#" ? `/${menu.linkurl}` : `${menu.linkurl}` }>
                   {menu.icon ?  <span className="position-absolute"><img src={`${menu.icon}`} alt={menu.title}/></span> : ""}  {menu.title}
                  </Link> */}
                  {/* Render the appropriate component based on the mega_menu type */}
                  {menu.isdropdown === "true" && <>
                    <SingleNabar3 submenu={menu.dropdown} title={menu.thisid} />
                  </>}
              </li>
          );
      })}
    </ul>
</>
  );
}

export default MainMenu1;
