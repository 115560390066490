import React, { useEffect } from 'react'
import { useMagazineContext } from '../Context/Magazineapi';
import { Link } from 'react-router-dom';

function Magazine() {
    const {magazine, loading, error, fetchAllMagazineHandler} = useMagazineContext();

    useEffect(()=>{
    fetchAllMagazineHandler()
    },[])

    function generateRandomNumber() {
        // Create an array of numbers from 1 to 5
        const numbers = [1, 2, 3, 4, 5];
    
        // Shuffle the array using Fisher-Yates algorithm
        for (let i = numbers.length - 1; i > 0; i--) {
            const randomIndex = Math.floor(Math.random() * (i + 1)); // Random index within bounds
            // Swap elements
            [numbers[i], numbers[randomIndex]] = [numbers[randomIndex], numbers[i]];
        }
    
        // Return the first element of the shuffled array
        return numbers[0];
    }
    
    

  return (
    <>
    <div className="edu-categorie-area categorie-area-2 p-5">
            <div className="container">
                <div className="section-title section-center">
                    <h2 className="title">Magazine</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                </div>

                <div className="row g-5">
                    {magazine.map((mag, index) =>(<Link to={`${mag.attachment}`} className="col-lg-4 col-md-6">
                        <div className={`categorie-grid categorie-style-2 color-extra0${generateRandomNumber()}-style`}>
                            <div className="icon">
                                <i className="icon-48"></i>
                            </div>
                            <div className="content">
                                <Link to={`${mag.attachment}`}>
                                    <h6 className="title">{mag.headline}</h6>
                                </Link>
                            </div>
                        </div>
                    </Link>))}
                </div>
            </div>
        </div>
    </>
  )
}

export default Magazine;