import { createContext, useState, useContext } from 'react';
import { fetchInterviewContent, fetchInterviewPanel } from '../../Config/api';

const InterviewContext = createContext();

export function InterviewProvider({ children }) {
    const [interviewDetails, setInterviewDetails] = useState({});
    const [interviewpanel, setInterviewpanel] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

        // Fetch single About data based on URL 
        const fetchinterviewContentHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchInterviewContent();
              setInterviewDetails(data.data[0])
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      };

        // Fetch Faculty data based on URL 
        const fetchinterviewPanelHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchInterviewPanel();
              setInterviewpanel(data.data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 
  
    return (
      <InterviewContext.Provider value={{ interviewDetails, interviewpanel, loading, error, fetchinterviewContentHandler, fetchinterviewPanelHandler}}>
        {children}
      </InterviewContext.Provider>
    );
  }
  
  export function useInterviewContext() {
    return useContext(InterviewContext);
  }