import React from 'react'
import  instructors_data  from "../../Data/instructors";
import Team from '../Team/Team1';

function Instructors1({faculty}) {
    console.log(faculty)
  return (
    <>
    <div className="edu-team-area team-area-1 gap-tb-text">
            <div className="container">
                <div className="section-title section-center">
                    {/* <span className="pre-title">Interview Panel</span> */}
                    <h2 className="title">Interview Panel</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                </div>
                <div className="row g-5">
                    {/* <!-- Start Instructor Grid  --> */}
                    {faculty.map((instructor) => (
                        <div key={instructor.id} className="col-lg-3 col-sm-6 col-12">
                            <Team instructor={instructor}/>
                        </div>
                    ))}
                    {/* <!-- End Instructor Grid  --> */}
                </div>
            </div>
        </div>
    </>
  )
}

export default Instructors1;