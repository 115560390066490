import React, { useState, useEffect } from 'react';
import Modal from '../ModalComponent/Modal';
// import phpfile from "../../Component/PhpComponent/Pop.php"

const Php = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Fetch the HTML content from the PHP page
    fetch('../../Component/PhpComponent/Pop.php') // URL of your PHP file
      .then((response) => response.text()) // Use response.text() to get raw HTML content
      .then((data) => {
        setHtmlContent(data);
      })
      .catch((error) => {
        console.error('Error fetching PHP content:', error);
      });
  }, []);

  return (
    <div>
      <h1>PHP Content in React</h1>
      <Modal/>
      {/* Render the fetched HTML content */}
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default Php;
