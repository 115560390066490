import { createContext, useState, useContext } from 'react';
import {fetchbooks} from '../../Config/api';

const BooksContext = createContext();

export function BooksProvider({ children }) {
    const [booksDetails, setBooksDetails] = useState([]);
    // const [aboutsectionDetails, setAboutsectionDetails] = useState({});
    // const [faculty, setfaculty] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

        // Fetch single About data based on URL 
        const fetchBooksByUrlHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchbooks();
              setBooksDetails(data.data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      };

      //   // Fetch single About data based on URL 
      //   const fetchAboutSectionByHomeHandler = async () => {
      //     setLoading(true);
      //     try {
      //         const data = await fetchAboutsecctionbyHomepage();
      //         setAboutsectionDetails(data.data[0])
      //         // return data.data; // Return branch data from response
      //     } catch (err) {
      //         console.error("Error fetching branch by URL:", err);
      //         setError("Failed to load branch by URL");
      //         return null;
      //     } finally {
      //         setLoading(false);
      //     }
      // }; 

      //   // Fetch Faculty data based on URL 
      //   const fetchFacultyHandler = async () => {
      //     setLoading(true);
      //     try {
      //         const data = await fetchfaculty();
      //         setfaculty(data.data)
      //         // return data.data; // Return branch data from response
      //     } catch (err) {
      //         console.error("Error fetching branch by URL:", err);
      //         setError("Failed to load branch by URL");
      //         return null;
      //     } finally {
      //         setLoading(false);
      //     }
      // }; 
  
    return (
      <BooksContext.Provider value={{ booksDetails, loading, error,fetchBooksByUrlHandler}}>
        {children}
      </BooksContext.Provider>
    );
  }
  
  export function useBooksContext() {
    return useContext(BooksContext);
  }