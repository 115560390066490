import React, { useEffect } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import t1 from "../../assets/images/testimonial/testimonial-01.png"
import { useAboutContext } from '../Context/Aboutapi';
import { useTestimonialContext } from '../Context/Testimonialapi';

function Testimonial() {
    const { aboutsectionDetails, faculty, loading, error, fetchAboutSectionByHomeHandler } =
    useAboutContext();

    const { Testimonial, fetchTestimonialHandler } =
    useTestimonialContext();

    useEffect(()=>{
        fetchAboutSectionByHomeHandler()
        fetchTestimonialHandler()

         // Swiper Slider Activation
    const swiper = new Swiper('.home-one-testimonial-activator', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: false,
        grabCursor: true,
        speed: 1500,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        breakpoints: { 
          577: {
            slidesPerView: 2
          },
        },
      });

      return () => {
        if (swiper) swiper.destroy(); // Clean up Swiper instance on component unmount
      };
      },[])

  return (
    <>
    <div className="testimonial-area-1 section-gap-equal pb-2">
            <div className="container">
                <div className="row g-lg-5">
                    <div className="col-lg-5">
                        <div className="testimonial-heading-area">
                            <div className="section-title section-left">
                                <span className="pre-title">Testimonials</span>
                                <h2 className="title">What Our Students Have To Say</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p dangerouslySetInnerHTML={{ __html: aboutsectionDetails.testimonialdescription }} />
                                <a href="/testimonial-details" className="edu-btn btn-large">View All<i className="icon-4"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="home-one-testimonial-activator swiper ">
                            <div className="swiper-wrapper">
 
{      Testimonial.map((Test,index)=>(  
                            <div className="swiper-slide">       
                                <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={Test.pic} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>{Test.testimonials}</p>
                                        <h6 className="title">{Test.name}</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    
    </>
  )
}

export default Testimonial;



{/* <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img src={Test.pic} style={{width: "80px"}} alt="Testimonial"/>
                                            <span className="qoute-icon"><i className="icon-26"></i></span>

                                        </div>
                                        <div className="content">
                                            <p>{Test.testimonials}</p>
                                            <h5 className="title">{Test.name}</h5>
                                            <span className="subtitle fs-4">Student</span>
                                        </div>
                                    </div> */}