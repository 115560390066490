import React from 'react';
import { Link } from 'react-router-dom';
import { useToggleContext } from '../../Context/Togglemanagement';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';
import { useValidateUrlContext } from '../../Context/Validateurl';

function SingleNabar3({submenu, title}) {
   const {isVisible, TogglecloseHandler} = useToggleContext();
   const { ScrollingopenHandler } = useScrollingManagementContext();
   const { validateURL } = useValidateUrlContext();
  return (
    <>
     <ul className={`submenu custom-single-drop ${isVisible !== "hide" ? '' : 'hiddenmobile-submenu'}`} onClick={()=>ScrollingopenHandler()}> 
    {submenu.map((course,index)=>(
     <li key={index} className=''><Link to={validateURL(course.linkurl) ? `${course.linkurl}` :`/${course.linkurl}`} onClick={()=>TogglecloseHandler()}>{course.title}</Link></li>
    ))}    
    </ul>
    </>
  )
}

export default SingleNabar3;