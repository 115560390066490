import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import './index.css';
// import '../public/assets/js/vendor/magnifypopup.min.js'
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/icomoon.css';
import './assets/css/vendor/remixicon.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from "react-alert-template-basic";
// import './bootstrap.min.js'
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import { StatuscodeProvider } from './Component/Context/StatusCode.js';


ReactGA.initialize("G-T02WQMFNWW"); // Replace with your Measurement ID

ReactGA.send("pageview");

const root = ReactDOM.createRoot(document.getElementById('root'));
// Alert options
const alertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  transition: transitions.SCALE,
};

root.render(
  <React.StrictMode>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
        <StatuscodeProvider>
    <App />
    </StatuscodeProvider>
        </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
