import { createContext, useState, useContext } from 'react';
import {fetchMagazine} from '../../Config/api';
import { useNotFoundContext } from './NotFound';
import { useStatuscodeContext } from './StatusCode';

const MagazineContext = createContext();

export function MagazineProvider({ children }) {
    const [magazine, setMagazine] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { pagefoundhandler } = useNotFoundContext();  
    const { statuscodehandler } = useStatuscodeContext(); 
   
      // Fetch all blogs data
      const fetchAllMagazineHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchMagazine();
            setMagazine(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

    return (
      <MagazineContext.Provider value={{magazine, loading, error, fetchAllMagazineHandler}}>
        {children}
      </MagazineContext.Provider>
    );
  }
  
  export function useMagazineContext() {
    return useContext(MagazineContext);
  }