import React from 'react';
import imgcourse from "../../assets/images/blog/blog-01.png";
import imglogo from "../../assets/images/course/course-111.jpg";
import { Link, useNavigate } from 'react-router-dom';
import { useScrollingManagementContext } from '../Context/ScrollingEventManagement';

function Coursecardlarge({courses, category}) {
  const navigate = useNavigate();
  const { ScrollingopenHandler } = useScrollingManagementContext();
  

  function link(url){
    navigate(url)
  }

  return (
    <>
{courses.map((course) => (<Link to={`/course-details/${course.courseurl}`} onClick={()=>ScrollingopenHandler()} className="col-12 col-xl-4 my-3 col-lg-6 col-md-6">
                        <div className="edu-team-grid team-style-5">
                            <div className="inner">
                                <div className="thumbnail-wrap">
                                    <div className="">
                                    <Link to={`/course-details/${course.courseurl}`}>
                    <img src={`${course.pic}`} style={{borderRadius: "8px"}} onError={(e) => {
                                        e.target.src = imgcourse;
                                    }} title={course.coursename} alt={course.coursename}/>
                  </Link>
                                    </div>
                                </div>
                                {/* <div className="content"> */}
                                    {/* <h6 className="title"><a href="team-details.html">{course.coursename}</a></h6> */}
                                    {/* <span className="designation">{course.cname}</span> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </Link>))}
    </>
  )
}

export default Coursecardlarge;