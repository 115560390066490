import React from 'react';
import blogimg from "../../../assets/images/blog/blog-25.jpg"
import { Link } from 'react-router-dom';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';

function BlogCard({blogs}) {
  const { ScrollingopenHandler } = useScrollingManagementContext();
  
  return (
    <>
    {blogs.map((post, index) => (
    <div className="edu-blog blog-style-list" onClick={()=>ScrollingopenHandler()} key={index}>
                            <div className="inner">
                                <div className="thumbnail responsive-blog-card" style={{width: "45%", display: "flex" , alignItems:"flex-start" , flexDirection:"column"}}>
                                    <Link to={`/blog/${post.url}/`} style={{borderRadius:"2px"}}>
                                        <img src={post?.pic || blogimg} alt="Blog Images responsive-image"/>
                                    </Link>
                                    <Link to={`/blog/${post.url}/`} className="edu-btn btn-small btn-secondary mt-3 bg-success" style={{borderRadius:"2px", width:"100%"}}>View Details<i class="icon-4 text-white"></i></Link>
                                </div>
                                <div className="content">
                                    <div>
                                    <h5 className="title responsive-blog-card-title"><Link to={`/blog/${post.url}/`}>{post.title}</Link></h5>
                                    </div>
                                    <p style={{textAlign:"justify", color:"black"}}>{post.metadescription}</p>
                                    <div style={{display:"flex" , flexDirection:"row-reverse" , gap: "20px", padding:"10px"}}>
                                      <div className='text-success custom-badge2' style={{fontSize:"9px" , height:"25px" }}><i className="icon-27 fs-5 text-success"></i> {post.blogpostdate}</div>
                                      <div style={{fontSize:"14px", paddingTop:"2px" , fontWeight:"600"}}><i className="icon-eye" style={{fontSize: "16px", color: "orangered"}}></i> <b>{post.view}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
    ))}
    </>
  )
}

export default BlogCard;