import { createContext, useState, useContext } from 'react';
import { submitQueryForm } from '../../Config/api';
import { useAlert } from 'react-alert';

const QueryContext = createContext();

export function QueryProvider({ children }) {
    // const [data, setdata] = useState([]);
    const [pagename, setpagename] = useState("Page Name");
    const [mainpage, setmainpage] = useState('')
    // const [pageurl, setpageurl] = useState([]);
    const alert = useAlert();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch single branch data based on URL 
    const QuerySubmitHandler = async (name,email,contact,query, pagename, pagurl) => {
        setLoading(true);
        try {
            const data = await submitQueryForm(name, email, contact,query, pagename, pagurl);
            // setBranchDetails(data.data.branch)
            // setCoursebybranch(data.data.courses)
            console.log(data)
            alert.show('Query Submit Successfully');
            // return data.data; // Return branch data from response
            return true;
        } catch (err) {
          // Extract error message from AxiosError
        const errorMessage = err.response?.data?.error || 'Failed to submit query';

            console.error("Error fetching branch by URL:", err);
            setError(errorMessage);
            alert.show(`Query Submit failed:  ${errorMessage}`);
            return false;
        } finally {
            setLoading(false);
        }
    }; 

    const getPagename = (url) =>{
setpagename(url)
    }

    const breadcrumbhandler =(name) =>{
      setmainpage(name)
    }
  
    return (
      <QueryContext.Provider value={{pagename, mainpage, loading, error, QuerySubmitHandler, getPagename, breadcrumbhandler }}>
        {children}
      </QueryContext.Provider>
    );
  }
  
  export function useQueryContext() {
    return useContext(QueryContext);
  }