import React from "react";
import Courses from "../Card/Coursescard";
import Courselist from "../Card/Courselist";
import ButtonDetails from "../ButtonDetails/ButtonDetails";
import {useButtonDetailsContext } from "../Context/Pagebuttonmanagement";
import Overview from "../ContentManagement/CourseContent/Overview/Overview";
import Review from "../ContentManagement/CourseContent/Review/Review";
import Instructor from "../ContentManagement/CourseContent/Instructor/Instructor";

function DisplayBatch() {
    const DetailscomponentName = 'Coursedetailpage';
    const {activeDetailsButton, buttonDetailsNames} = useButtonDetailsContext();

  //   const tabConfigurations = {
  //     'Our Courses': <Overview/>,
  //     'Overview': <Review/>,
  //     'Curriculum': <Instructor/> 
  // };

  // Generate a dynamic object mapping using reduce
const tabConfigurations = buttonDetailsNames.reduce((acc, tab) => {
  acc[tab.label] = tab.component;
  return acc;
}, {});

  
  function getTabs(componentName) {
    return tabConfigurations[componentName] || [];  // Returns an empty array if componentName is not found
}

const tabs = getTabs(activeDetailsButton);
  return (
    <>
      <div className="edu-breadcrumb-area breadcrumb-style-8">
        {/* <div className="edu-breadcrumb-wrapper"> */}
        {/* <div className="container">
                    <ul className="edu-breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="separator"><i className="icon-angle-right"></i></li>
                        <li className="breadcrumb-item"><a href="course-one.html">Courses</a></li>
                        <li className="separator"><i className="icon-angle-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Course Details</li>
                    </ul>
                </div> */}
        {/* </div> */}
        <div className="breadcrumb-right-wrapper">
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="page-title">
                <h2 className="title">
                  Global Education Fall Meeting for Everyone
                </h2>
              </div>
              <ul className="course-meta">
                <li>
                  <i className="icon-58"></i>by Edward Norton
                </li>
                <li>
                  <i className="icon-59"></i>English
                </li>
                <li className="course-rating">
                  <div className="rating">
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                  </div>
                  <span className="rating-count">(720 Rating)</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-6 scene">
            <img
              data-depth="2"
              src="assets/images/others/shape-81.png"
              alt="shape"
            />
          </li>
          <li className="shape-7 scene">
            <img
              data-depth="-2"
              src="assets/images/others/shape-65.png"
              alt="shape"
            />
          </li>
          <li className="shape-8">
            <img src="assets/images/cta/shape-08.png" alt="shape" />
          </li>
          <li className="shape-9 scene">
            <img
              data-depth="-2"
              src="assets/images/others/shape-79.png"
              alt="shape"
            />
          </li>
        </ul>
      </div>
      <section className="edu-section-gap course-details-area">
        <div className="container">
          <div className="row row--30">
            <div className="col-lg-8">
              <div className="course-details-content course-details-7">
                
                <ButtonDetails DetailscomponentName={DetailscomponentName}/>
               
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-overview">
                        <Courselist/>
                        {tabs}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-overview">
                        <h3 className="heading-title">Course Description</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor inc idid unt ut labore et
                          dolore magna aliqua enim ad minim veniam, quis nostrud
                          exerec tation ullamco laboris nis aliquip commodo
                          consequat duis aute irure dolor in reprehenderit in
                          voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur enim ipsam.
                        </p>
                        <p className="mb--60">
                          Excepteur sint occaecat cupidatat non proident sunt in
                          culpa qui officia deserunt mollit anim id est laborum.
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium totam rem
                          aperiam.
                        </p>
                        <h5 className="title">What You’ll Learn?</h5>
                        <ul className="mb--60">
                          <li>
                            Learn to use Python professionally, learning both
                            Python 2 & Python 3!
                          </li>
                          <li>
                            Build 6 beautiful real-world projects for your
                            portfolio (not boring toy apps)
                          </li>
                          <li>
                            Understand the Theory behind Vue.js and use it in
                            Real Projects
                          </li>
                          <li>
                            Create responsive, accessible, and beautiful layouts
                          </li>
                        </ul>
                        <p>
                          Consectetur adipisicing elit, sed do eiusmod tempor
                          inc idid unt ut labore et dolore magna aliqua enim ad
                          minim veniam quis nostrud exerec tation ullamco
                          laboris nis aliquip commodo consequat duis aute irure
                          dolor.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="carriculam"
                    role="tabpanel"
                    aria-labelledby="carriculam-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-curriculam">
                        <h3 className="heading-title">Course Curriculum</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor inc idid unt ut labore et
                          dolore magna aliqua enim ad minim veniam, quis nostrud
                          exerec tation ullamco laboris nis aliquip commodo
                          consequat.
                        </p>
                        <div className="course-lesson">
                          <h5 className="title">Week 1-4</h5>
                          <p>
                            Advanced story telling techniques for writers:
                            Personas, Characters & Plots
                          </p>
                          <ul>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Introduction
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Course Overview
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Local Development
                                Environment Tools
                              </div>
                              <div className="badge-list">
                                <span className="badge badge-primary">
                                  0 Question
                                </span>
                                <span className="badge badge-secondary">
                                  10 Minutes
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Course Exercise /
                                Reference Files
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Code Editor
                                Installation (Optional if you have one)
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Embedding PHP in
                                HTML
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="course-lesson">
                          <h5 className="title">Week 5-8</h5>
                          <p>
                            Advanced story telling techniques for writers:
                            Personas, Characters & Plots
                          </p>
                          <ul>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Defining Functions
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i>Function Parameters
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Return Values From
                                Functions
                              </div>
                              <div className="badge-list">
                                <span className="badge badge-primary">
                                  0 Question
                                </span>
                                <span className="badge badge-secondary">
                                  10 Minutes
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Global Variable and
                                Scope
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i>Newer Way of creating
                                a Constant
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> Constants
                              </div>
                              <div className="icon">
                                <i className="icon-68"></i>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="instructor"
                    role="tabpanel"
                    aria-labelledby="instructor-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-instructor">
                        <div className="thumbnail">
                          <img
                            src="assets/images/course/author-01.png"
                            alt="Author Images"
                          />
                        </div>
                        <div className="author-content">
                          <h6 className="title">Edward Norton</h6>
                          <span className="subtitle">Founder & CEO</span>
                          <p>
                            Consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt labore et dolore magna aliqua enim minim
                            veniam quis nostrud exercitation ulla mco laboris
                            nisi ut aliquip ex ea commodo consequat. duis aute
                            irure dolor in reprehenderit in voluptate.
                          </p>
                          <ul className="social-share">
                            <li>
                              <a href="#">
                                <i className="icon-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="icon-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="icon-linkedin2"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="icon-youtube"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="review"
                    role="tabpanel"
                    aria-labelledby="review-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-review">
                        <h3 className="heading-title">Course Rating</h3>
                        <p>5.00 average rating based on 7 rating</p>
                        <div className="row g-0 align-items-center">
                          <div className="col-sm-4">
                            <div className="rating-box">
                              <div className="rating-number">5.0</div>
                              <div className="rating">
                                <i className="icon-23"></i>
                                <i className="icon-23"></i>
                                <i className="icon-23"></i>
                                <i className="icon-23"></i>
                                <i className="icon-23"></i>
                              </div>
                              <span>(7 Review)</span>
                            </div>
                          </div>
                          <div className="col-sm-8">
                            <div className="review-wrapper">
                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  5 <i className="icon-23"></i>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "100%" }}
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <span className="rating-value">7</span>
                              </div>

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  4 <i className="icon-23"></i>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "0%" }}
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <span className="rating-value">0</span>
                              </div>

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  4 <i className="icon-23"></i>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "0%" }}
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <span className="rating-value">0</span>
                              </div>

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  4 <i className="icon-23"></i>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "0%" }}
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <span className="rating-value">0</span>
                              </div>

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  4 <i className="icon-23"></i>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "0%" }}
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <span className="rating-value">0</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Start Comment Area */}
                        <div className="comment-area">
                          <h3 className="heading-title">Reviews</h3>
                          <div className="comment-list-wrapper">
                            {/* <!-- Start Single Comment  --> */}
                            <div className="comment">
                              <div className="thumbnail">
                                <img
                                  src="assets/images/blog/comment-01.jpg"
                                  alt="Comment Images"
                                />
                              </div>
                              <div className="comment-content">
                                <div className="rating">
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                </div>
                                <h5 className="title">Haley Bennet</h5>
                                <span className="date">Oct 10, 2024</span>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                </p>
                              </div>
                            </div>
                            {/* <!-- End Single Comment  --> */}
                            {/* <!-- Start Single Comment  --> */}
                            <div className="comment">
                              <div className="thumbnail">
                                <img
                                  src="assets/images/blog/comment-02.jpg"
                                  alt="Comment Images"
                                />
                              </div>
                              <div className="comment-content">
                                <div className="rating">
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                </div>
                                <h5 className="title">Simon Baker</h5>
                                <span className="date">Oct 10, 2024</span>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                </p>
                              </div>
                            </div>
                            {/* <!-- End Single Comment  --> */}
                            {/* <!-- Start Single Comment  --> */}
                            <div className="comment">
                              <div className="thumbnail">
                                <img
                                  src="assets/images/blog/comment-03.jpg"
                                  alt="Comment Images"
                                />
                              </div>
                              <div className="comment-content">
                                <div className="rating">
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                  <i className="icon-23"></i>
                                </div>
                                <h6 className="title">Richard Gere</h6>
                                <span className="date">Oct 10, 2024</span>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                </p>
                              </div>
                            </div>
                            {/* <!-- End Single Comment  --> */}
                          </div>
                        </div>
                        {/* <!-- End Comment Area  --> */}
                        <div className="comment-form-area">
                          <h3 className="heading-title">Write a Review</h3>
                          <div className="rating-icon">
                            <h6 className="title">Rating Here</h6>
                            <div className="rating">
                              <i className="icon-23"></i>
                              <i className="icon-23"></i>
                              <i className="icon-23"></i>
                              <i className="icon-23"></i>
                              <i className="icon-23"></i>
                            </div>
                          </div>
                          <form className="comment-form">
                            <div className="row g-5">
                              <div className="form-group col-lg-6">
                                <input
                                  type="text"
                                  name="comm-title"
                                  id="comm-title"
                                  placeholder="Review Title"
                                />
                              </div>
                              <div className="form-group col-lg-6">
                                <input
                                  type="text"
                                  name="comm-name"
                                  id="comm-name"
                                  placeholder="Reviewer name"
                                />
                              </div>
                              <div className="form-group col-12">
                                <input
                                  type="email"
                                  name="comm-email"
                                  id="comm-email"
                                  placeholder="Reviewer email"
                                />
                              </div>
                              <div className="form-group col-12">
                                <textarea
                                  name="comm-message"
                                  id="comm-message"
                                  cols="30"
                                  rows="5"
                                  placeholder="Review summary"
                                ></textarea>
                              </div>
                              <div className="form-group col-12">
                                <button
                                  type="submit"
                                  className="edu-btn submit-btn"
                                >
                                  Submit Review <i className="icon-4"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="course-sidebar-3 sidebar-top-position">
                <div className="edu-course-widget widget-course-summery">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="assets/images/course/course-46.jpg"
                        alt="Courses"
                      />
                      {/* <a
                        href="https://www.youtube.com/watch?v=PICj5tr9hcc"
                        className="play-btn video-popup-activation"
                      >
                        <i className="icon-18"></i>
                      </a> */}
                    </div>
                    <div className="content">
                      <h4 className="widget-title">Course Includes:</h4>
                      <ul className="course-item">
                        <li>
                          <span className="label">
                            <i className="icon-60"></i>Price:
                          </span>
                          <span className="value price">$70.00</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-62"></i>Instrutor:
                          </span>
                          <span className="value">Edward Norton</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-61"></i>Duration:
                          </span>
                          <span className="value">3 weeks</span>
                        </li>
                        <li>
                          <span className="label">
                            <img
                              className="svgInject"
                              src="assets/images/svg-icons/books.svg"
                              alt="book icon"
                            />
                            Lessons:
                          </span>
                          <span className="value">8</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-63"></i>Enrolled:
                          </span>
                          <span className="value">65 students</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-59"></i>Language:
                          </span>
                          <span className="value">English</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-64"></i>Certificate:
                          </span>
                          <span className="value">Yes</span>
                        </li>
                      </ul>
                      {/* <div className="read-more-btn">
                        <a href="#" className="edu-btn">
                          Start Now <i className="icon-4"></i>
                        </a>
                      </div> */}
                      {/* <div className="share-area">
                        <h4 className="title">Share On:</h4>
                        <ul className="social-share">
                          <li>
                            <a href="#">
                              <i className="icon-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icon-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icon-linkedin2"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icon-youtube"></i>
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="gap-bottom-equal" style={{ paddingBottom: "5px" }}>
        {/* <div className="container"> */}
        {/* <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <h3 className="title">More Courses for You</h3>
                </div> */}
        {/* <div className="edu-course-area course-area-1 edu-section-gap bg-lighten01">
          <div className="container">
            <div
              className="section-title section-center"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <span className="pre-title">Popular Batch</span>
              <h2 className="title">Pick A Batch To Get Started</h2>
              <span className="shape-line">
                <i className="icon-19"></i>
              </span>
            </div>
          </div>
          <div className="container">
            <Courses />
          </div>
        </div> */}
      </div>
    </>
  );
}

export default DisplayBatch;
