import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useBranchContext } from '../../Context/Branchapi';
import { useToggleContext } from '../../Context/Togglemanagement';
import { useCourseContext } from '../../Context/Courseapi';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';
import { useBranchwiseidContext } from '../../Context/BranchwiseCourse';
import FAQdrodown from '../../FAQ/FAQdropdown';

function SingleNabar() {
  const {isVisible} = useToggleContext();
  const { ScrollingopenHandler } = useScrollingManagementContext();

  return (
    <>
     <ul className={`submenu custom-single-drop ${isVisible !== "hide" ? '' : 'hidden-submenu'} responsive-custom-dropdown`} onClick={()=>ScrollingopenHandler()}>
     <FAQdrodown/>
     {/* {courses.map((branch) => (
                <li onClick={()=>branchwiseCourseidhandlerDefault()} key={branch.branchurl}>
                    <Link to={`/course-details/${branch.courseurl}`} onClick={()=>TogglecloseHandler()}><span className='course-level bg-primary text-white p-2 py-1 rounded-circle' style={{width: "50px", height: "50px"}}><i className='icon-20 fs-6'></i></span> &nbsp; {branch.coursename}</Link>
                </li>
            ))} */}
                                            </ul>
    </>
  )
}

export default SingleNabar;