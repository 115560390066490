import React from 'react';
import img from "../../assets/images/banner/BG-1.webp";
import zIndex from '@mui/material/styles/zIndex';
import { Link } from 'react-router-dom';

function Coursescard({batchs, branchname}) {


  return (
    <>
    {/* .edu-course .content { */}
                    <div className="row g-5">
                    {/* <!-- Start Single Course  --> */}
                    {batchs.map((batch, index) => (
                    <div className="col-md-6 col-xl-3" key={index}>
                        <div className="edu-course course-style-1 hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="course-details.html">
                                        <img src={batch.pic} onError={(e) => {
                                        e.target.src = img;
                                    }} className='responsive-image' alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-59"></i>{branchname.length === 4 || branchname.length === 3 ? branchname[(batch.branchid-1)] : branchname[0]}</span>
                                    </div>
                                </div>
                                <div className="content custom-content1">
                                    <span className="course-level bg-light text-success fw-bold fs-4 px-0 py-2 pb-0" style={{backgroundColor: "#fff"}}><span><img src='https://admin.sriramsias.com/images/batchicon.png' alt=''/></span> &nbsp;{batch.packagename}</span>
                                    <p className="title text-secondary fs-5 py-0">
                                        <a href="#" className='text-secondary'><span className='fw-bold text-dark fs-4'>Batch Name:</span> {batch.batchname}</a>
                                    </p>
                                    <p className="title fs-5 py-0">
                                    <span className='fw-bold text-dark fs-4'>Batch Time:</span>&nbsp;{batch.bstarttime}
                                    </p>
                                    {/* <p className="title">
                                    <i className="icon-59"></i> &nbsp;&nbsp;{batch.bmedium}
                                    </p> */}
                                    {/* <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.9 /8 Rating)</span>
                                    </div> */}
                                    <div className="course-price text-success fs-5"><span className='fw-bold text-dark fs-4'>Price:</span>  ₹{batch.price}&nbsp;&nbsp; {batch.price !== batch.pricex && <span className='text-decoration-line-through text-danger'>₹{batch.pricex}</span>}</div>
                                    <ul className="course-meta d-flex align-items-center">
                                        <li className="title fw-bold fs-4" style={{textTransform: "uppercase"}}><i className="icon-59 fw-bold text-dark"></i>{batch.type}
                                        </li>
                                        <Link to={`/enrollnow/${batch.batchid}`} className="edu-btn btn-secondary btn-small">Enroll Now</Link>
                                        {/* <li className='text-white ps-1 py-0'><a href="course-details.html" className="edu-btn1 edu-btn btn-light btn-small px-3">Enroll now <i className="icon-4 text-white fs-6"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    {/* <button className="wishlist-btn"><i className="icon-22"></i></button> */}
                                    <span className="course-level py-2 fs-6">{branchname[(batch.branchid-1)]}</span>
                                    <p className='text-white fw-bold fs-4'>{batch.packagename}</p>
                                    <p className="title text-white fs-5">
                                        <a href="" className='text-white'><span className='fw-bold text-white fs-4'>Batch Name:</span>&nbsp;{batch.batchname}</a>
                                    </p>
                                    <p className="title text-white fs-5">
                                        <a href="" className='text-white'><span className='fw-bold text-white fs-4'>Academic Year:</span>&nbsp;{batch.ay}</a>
                                    </p>
                                    <p className="title text-white fs-5">
                                        <a href="" className='text-white'><span className='fw-bold text-white fs-4'>Medium:</span>&nbsp;{batch.bmedium}</a>
                                    </p>
                                    <p className="title text-white fs-5">
                                    <span className='fw-bold text-white fs-4'>Batch Time:</span>&nbsp;{batch.bstarttime}
                                    </p>
                                    <p className="title fs-5">
                                    <span className='fw-bold text-white fs-4'>Batch Date:</span>&nbsp;{batch.bstartdate}
                                    </p>
                                    {/* <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.9 /8 Rating)</span>
                                    </div> */}
                                    <div className="course-price text-white fs-5"><span className='fw-bold text-white fs-4'>Price:</span> &nbsp; ₹{batch.price} &nbsp; {batch.price !== batch.pricex && <span className='text-decoration-line-through text-secondary text-danger'>₹{batch.pricex}</span>}</div>
                                   
                                    <ul className="course-meta">
                                    <li className="title fw-bold fs-4" style={{textTransform: "uppercase"}}><i className="icon-59 fw-bold text-white"></i>{batch.type}
                                    </li>
                                        {/* <li><i className="icon-25"></i>Enroll Now</li> */}
                                    </ul>
                                    {/* <a href="course-details.html" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                     ))}
                                         {/* <!-- Start Single Course  --> */}
                </div>
    
    </>
  )
}

export default Coursescard;