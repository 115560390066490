import React, { useEffect } from 'react';
import { useButtonDetailsContext } from '../Context/Pagebuttonmanagement';

function ButtonDetails({ DetailscomponentID, DetailscomponentName, defaultLabel }) {
  const {
    buttonDetailsState,
    updateButtonDetailsNames,
    handleDetailsButtonClick,
  } = useButtonDetailsContext();

  // Get the state specific to this component ID
  const { buttonDetailsNames = [], activeDetailsButton = '' } =
    buttonDetailsState[DetailscomponentID] || {};

  useEffect(() => {
    if (!buttonDetailsState[DetailscomponentID]?.buttonDetailsNames) {
      updateButtonDetailsNames(DetailscomponentID, DetailscomponentName, defaultLabel);
    }
  }, [DetailscomponentID, DetailscomponentName, buttonDetailsState, updateButtonDetailsNames]);

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {buttonDetailsNames.map((button) => (
          <li className="nav-item" role="presentation" key={button.id}>
            <button
              className={`nav-link ${activeDetailsButton === button.label ? 'active' : ''}`}
              id={button.id}
              aria-selected={activeDetailsButton === button.label ? 'true' : 'false'}
              onClick={() => handleDetailsButtonClick(DetailscomponentID, button.label)}
            >
              {button.label}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}

export default ButtonDetails;
