import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import CustomEnquery from '../Form/CustomEnqueryPop';
import { useWebdetailsContext } from '../Context/WebDetails';

function ModalComponent({open, setOpen, handleClose}) {
  const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
  useWebdetailsContext();

  useEffect(() => {
    fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
  }, []); 

  // Handlers to open and close the modal
  


  // Styles for the modal's content
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    // animation: "pulse 1s ease-out forwards",
  };

  const Header1 = Webdetails?.header2 || "Header Here";
  const title1 = Webdetails?.title2 || "Title Here";
  const title2 = Webdetails?.subtitle2 || "subtitle Here";

  return (
    <div>
      {/* The Modal component */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" className='text-center highlight-modal-custom-background text-white px-4 py-4' variant="h5" component="h2">
          {Header1} 
          </Typography>
          <Typography id="modal-title" className='text-center pt-2' variant="h6" component="h2">
          {title1} 
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
             <CustomEnquery handleClose={handleClose} head={Header1} title={title1} subtile={title2}/>
          </Typography>
          {/* Close button inside the modal */}
          
        </Box>
      </Modal>
    </div>
  );
}

export default ModalComponent;
