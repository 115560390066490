import React, { useEffect, useMemo } from 'react';
import { useButtonDetailsContext } from '../Context/Pagebuttonmanagement';
import { useCourseContext } from '../Context/Courseapi';

function ButtonDetailsWithBorder({ DetailscomponentID, DetailscomponentName, defaultLabel }) {
  const {
    buttonDetailsState,
    updateButtonDetailsNames,
    handleDetailsButtonClick,
  } = useButtonDetailsContext();

  const {coursesDetails, facultybyCourse} = useCourseContext();

  // Get the state specific to this component ID
  const { buttonDetailsNames = [], activeDetailsButton = '' } =
    buttonDetailsState[DetailscomponentID] || {};

  useEffect(() => {
    if (!buttonDetailsState[DetailscomponentID]?.buttonDetailsNames) {
      updateButtonDetailsNames(DetailscomponentID, DetailscomponentName, defaultLabel);
    }
  }, [DetailscomponentID, DetailscomponentName, buttonDetailsState, updateButtonDetailsNames]);

  const columnClass = buttonDetailsNames.length === 2 
  ? 'col-lg-3' 
  : buttonDetailsNames.length === 4 
  ? 'col-lg-3 col-md-6 col-sm-12' 
  : buttonDetailsNames.length > 4 
  ? 'col-lg-2 col-md-3 col-sm-6' 
  : 'col-lg-3 col-md-6 col-sm-12';

 // Filter buttons based on `coursesDetails` and `facultybyCourse`
 const updatedCourseDetailsButton = useMemo(() => {
  let filteredButtons = [...buttonDetailsNames];
  if (!coursesDetails?.details) {
    filteredButtons = filteredButtons.filter((item) => item.id !== 'Overview-tab');
  }
  if (coursesDetails?.syllabus === '') {
    filteredButtons = filteredButtons.filter((item) => item.id !== 'syllabus-tab');
  }
  if (coursesDetails?.learnit === '') {
    filteredButtons = filteredButtons.filter((item) => item.id !== 'What-You’ll-Learn-tab');
  }
  if (facultybyCourse.length === 0) {
    filteredButtons = filteredButtons.filter((item) => item.id !== 'Faculty-tab');
  }
  return filteredButtons;
}, [coursesDetails, facultybyCourse, buttonDetailsNames]);

  return (
    <>
      <div className='p-5 px-0 py-0'>
      <div className='d-flex flex-wrap'>
        {updatedCourseDetailsButton.map((button, index) => (
          <div key={index} className='m-1 flex-grow-1'>
            <h6
              className={`custombutton text-center ${
                activeDetailsButton === button.label ? 'custombuttonactive' : ''
              } ${
                button.label === 'BUY NOW' ? 'buy-now-button' : ''
              }`}
              onClick={() => handleDetailsButtonClick(DetailscomponentID, button.label)}
            >
              {button.label}
            </h6>
          </div>
        ))}
      </div>
    </div>
    </>
  );
}

export default ButtonDetailsWithBorder;
