import { createContext, useState, useContext, useEffect } from 'react';
import { useQueryContext } from './QueryManagement';

const BranchwiseidContext = createContext();

export function BranchwiseidProvider({ children }) {
    const [branchwiseCourseid, setBranchwiseCourseid] = useState(null);

    const { pagename } = useQueryContext();  

    const branchwiseCourseidhandler = (branchid) =>{
        setBranchwiseCourseid(branchid)
    }

    const branchwiseCourseidhandlerDefault = () =>{
        if(pagename !=="old-rajinder-nagar" && pagename !=="mukherjee-nagar" && pagename !=="pune" && pagename !== "hyderabad"){
            setBranchwiseCourseid(null)
        }
    }
  
    return (
      <BranchwiseidContext.Provider value={{branchwiseCourseid, branchwiseCourseidhandler, branchwiseCourseidhandlerDefault }}>
        {children}
      </BranchwiseidContext.Provider>
    );
  }
  
  export function useBranchwiseidContext() {
    return useContext(BranchwiseidContext);
  }