import React, { useEffect, useState, useCallback, useMemo, Suspense } from "react";
// import Courses from "../Card/Coursescard";
import { useParams } from "react-router-dom";
import cdetails from "../../assets/images/others/shape-79.png";
import cshape from "../../assets/images/svg-icons/books.svg";
import cshape1 from "../../assets/images/cta/shape-08.png";
import cshape2 from "../../assets/images/others/shape-65.png";
import cshape3 from "../../assets/images/others/shape-81.png";
import clogo from "../../assets/images/svg-icons/books.svg";
import { useBackgroundContext } from "../Context/BackgroundChange";
// import ButtonDetails from "../ButtonDetails/ButtonDetails";
import { useButtonDetailsContext } from "../Context/Pagebuttonmanagement";
import CustomEnquery from "../Form/CustomEnquery";
// import Keywords from "../Keywords/keywords";
import SEO from "../SEO/SEO";
import { useContentContext } from "../Context/ContentManagement";
import { useTestContext } from "../Context/Testpi";
const  ButtonDetails = React.lazy(() => import("../ButtonDetails/ButtonDetails"));
const  Courses = React.lazy(() => import("../Card/Coursescard"));
const  Keywords = React.lazy(() => import("../Keywords/keywords"));

function Testdetails() { 
  const [filterbyBranch, setFilterbyBranch] = useState([]);
  const DetailscomponentName1 = "CourseDetailsButton";
  const DetailscomponentName = "BatchCategory";
  // const DetailscomponentName = "";
  const { testurl } = useParams(); // Extracts branchUrl from the URL
  const {
    testDetails,
    BatchbyTest,
    loading,
    error,
    fetchTestByUrlHandler,
  } = useTestContext();

  const { backgroundStyle, changeimagehandler } = useBackgroundContext();

  const {buttonDetailsState} = useButtonDetailsContext();

    // Get the state specific to this component ID
    const { buttonDetailsNames = [], activeDetailsButton = '' } =
    buttonDetailsState["CourseDetailsButton"] || {};

  // const { Content, setContent } = useContentContext();

  const { batchselectedid } = useButtonDetailsContext();
  console.log(batchselectedid);

  useEffect(() => {
    fetchTestByUrlHandler(testurl); // Fetch branches on component mount
  }, [testurl]);

  // Delay changeimagehandler until coursesDetails.pic is available
  useEffect(() => {
    // if (coursesDetails?.pic) {
    const timeoutId = setTimeout(() => {
      // changeimagehandler(coursesDetails.pic);
    }, 500); // Delay for 300ms (adjust as needed)

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount or change
    // }
  }, [testDetails.pic]);

  useEffect(() => {
    console.log("clling this");
    if (Array.isArray(BatchbyTest) && batchselectedid) {
      const filteredBatches = BatchbyTest.filter(
        (batch) => batch.branchid === batchselectedid
      );
      setFilterbyBranch(filteredBatches);
    }
  }, [batchselectedid, BatchbyTest]);

    //   useEffect(()=>{
    //   setContent(coursesDetails?.details)
    // },[coursesDetails?.details])

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error loading course details. {error}</p>;

  const CourseName = testDetails?.coursename || "Course Name";
  const CoursePic = testDetails?.pic || cdetails;
  const CourseDuration = testDetails?.duration || "0";
  const detailsHTML = testDetails?.details || "";
  const syllabusHTML = testDetails?.syllabus || "";
  const learnitHTML = testDetails?.learnit || "";
  const Batch = testDetails?.totalBatch || "";
  const brochure1 = testDetails?.brochure1 || "No link";
  const branchname = testDetails?.branchNames || [];
  const metatitle = testDetails?.metatitle || '';
  const metadescription = testDetails?.metadescription || '';
  // Extract and clean the keywords into an array
  // const keywordsArray =
  //   coursesDetails?.keywords.split(",").map((keyword) => keyword.trim()) || [];

  const keywordsArray = testDetails?.keywords ? testDetails.keywords.split(",").map((keyword) => keyword.trim()) : [];




  // const filterbyBranch = [];
  // useEffect(()=>{
  //   filterbyBranch = BatchbyCourse.filter(batch => batch.branchid === batchselectedid)
  // },[batchselectedid])

  return (
    <>
    <SEO seot1={metatitle} seot2={metadescription}/>
      <div className="edu-breadcrumb-area breadcrumb-style-8">
        <div className="breadcrumb-right-wrapper" style={backgroundStyle}>
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="page-title">
                <h2 className="title custom-heading">{CourseName}</h2>
              </div>
              <ul className="course-meta custom-box">
                {branchname.map((branch, index) => (
                  <li key={index}>
                    <i className="icon-59"></i> {branch}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-6 scene">
            <img data-depth="2" src={cshape3} alt="shape" />
          </li>
          <li className="shape-7 scene">
            <img data-depth="-2" src={cshape2} alt="shape" />
          </li>
          <li className="shape-8">
            <img src={cshape1} alt="shape" />
          </li>
          <li className="shape-9 scene">
            <img data-depth="-2" src={cshape} alt="shape" />
          </li>
        </ul>
      </div>
      <section className="edu-section-gap course-details-area">
        <div className="container">
          <div className="row row--30">
            <div className="col-lg-8">
              <div className="course-details-content course-details-7">
              <Suspense fallback={<p>This is Loading. branch</p>}>
                <ButtonDetails DetailscomponentID={DetailscomponentName1} DetailscomponentName={DetailscomponentName1} defaultLabel={""} />
</Suspense>
                <div className="tab-content" id="myTabContent">
        {buttonDetailsNames.map((button) => (
          <div
            key={button.id}
            className={`tab-pane fade ${activeDetailsButton === button.label ? 'show active' : ''}`}
            id={button.id}
            role="tabpanel"
            aria-labelledby={`${button.id}-tab`}
          >
            <div className="course-tab-content">
              <div className="course-overview">
                {/* Render content dynamically */}
                {/* {b} */}
                {button.label === 'Overview' && <p dangerouslySetInnerHTML={{ __html: detailsHTML }} />}
                {button.label === 'Syllabus' && <p dangerouslySetInnerHTML={{ __html: syllabusHTML }} />}
                {button.label === 'What You’ll Learn' && <p dangerouslySetInnerHTML={{ __html: learnitHTML }} />}
              </div>
            </div>
          </div>
        ))}
      </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="course-sidebar-3 sidebar-top-position">
                <div className="edu-course-widget widget-course-summery">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src={CoursePic} alt="Courses" />
                    </div>
                    <div className="content">
                      <h4 className="widget-title custom-heading1">
                        {CourseName}
                      </h4>
                      <ul className="course-item">
                        <li>
                          <span className="label">
                            <i className="icon-61"></i>Duration:
                          </span>
                          <span className="value">{CourseDuration}</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-62"></i>Batches:
                          </span>
                          <span className="value">{Batch}</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-59"></i>Branches:
                          </span>
                          <span className="value">{branchname.length}</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-65"></i>Brochure:
                          </span>
                          <span className="value">{brochure1}</span>
                        </li>
                        {/* <li>
                          <span className="label">
                            <img
                              className="svgInject"
                              src={clogo}
                              alt="book icon"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Broche
                          </span>
                          <span className="value">8</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-63"></i>Enrolled:
                          </span>
                          <span className="value">65 students</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-59"></i>Language:
                          </span>
                          <span className="value">English</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-64"></i>Certificate:
                          </span>
                          <span className="value">Yes</span>
                        </li> */}
                      </ul>
                      <div className="read-more-btn">
                        <a href="#coursebatchsection" className="text-white edu-btn">
                          View Batches<i className="icon-4"></i>
                        </a>
                      </div>
                      {/* <div className="share-area">
                        <h4 className="title">Share On:</h4>
                        <ul className="social-share">
                          <li>
                            <a href="#">
                              <i className="icon-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icon-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icon-linkedin2"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icon-youtube"></i>
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
                <CustomEnquery />

                <div className="col-lg-12 mt-5 pt-5">
                  <div className="contact-form form-style-2 px-0 py-0">
                    <div className="mt-5">
                    <Suspense fallback={<p>This is Loading. branch</p>}>
                      <Keywords keyword={keywordsArray} />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="gap-bottom-equal" id="coursebatchsection" style={{ paddingBottom: "5px" }}>
        {/* <div className="container"> */}
        {/* <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <h3 className="title">More Courses for You</h3>
                </div> */}
        <div className="edu-course-area course-area-1 edu-section-gap bg-lighten01">
          <div className="container">
            <div className="section-title section-center">
              <span className="pre-title">Popular Batch</span>
              <h2 className="title">Pick A Batch To Get Started</h2>
              <span className="shape-line">
                <i className="icon-19"></i>
              </span>
              <div>
                <div className="course-details-content pt-4">
                <Suspense fallback={<p>This is Loading. branch</p>}>
                  <ButtonDetails DetailscomponentID={DetailscomponentName} DetailscomponentName={DetailscomponentName} defaultLabel={""} />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
          <Suspense fallback={<p>This is Loading. branch</p>}>
            <Courses batchs={filterbyBranch} branchname={branchname} />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testdetails;
