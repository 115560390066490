import React from 'react';
import cta1 from "../../assets/images/cta/shape-06.png";
import cta2 from "../../assets/images/cta/shape-12.png";
import cta3 from "../../assets/images/cta/shape-04.png";


function CTA() {
  return (
    <>
    <div className="home-one-cta-two cta-area-1">
            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <div className="home-one-cta edu-cta-box bg-image">
                            <div className="inner">
                                <div className="content text-md-end">
                                    <span className="subtitle">Get In Touch:</span>
                                    <h3 className="title"><a href="mailto:sriramsias@gmail.com">sriramsias@gmail.com</a></h3>
                                </div>
                                <div className="sparator">
                                    <span>or</span>
                                </div>
                                <div className="content">
                                    <span className="subtitle">Call Us Via:</span>
                                    <h3 className="title"><a href="tel:+91 - 9811489560">+91 - 98 11 48 9560</a></h3>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <li className="shape-01 scene">
                                    <img data-depth="2" src={cta1} alt="shape"/>
                                </li>
                                <li className="shape-02 scene">
                                    <img data-depth="-2" src={cta2} alt="shape"/>
                                </li>
                                <li className="shape-03 scene">
                                    <img data-depth="-3" src={cta3} alt="shape"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </>
  )
}

export default CTA;