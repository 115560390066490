import { createContext, useState, useContext, useRef, useEffect } from 'react';

const ToggleContext = createContext();

export function ToggleProvider({ children }) {
  const [isVisible, setIsVisible] = useState("see");

  // Function to handle the navigation click event
  const TogglecloseHandler = () => {
    setIsVisible("hide");
  };

  const ToggleopenHandler = () =>{
    console.log("i am working")
    setIsVisible("see")
  }

  return (
    <ToggleContext.Provider value={{ isVisible, TogglecloseHandler, ToggleopenHandler }}>
      {children}
    </ToggleContext.Provider>
  );
}

export function useToggleContext() {
  return useContext(ToggleContext);
}
