import React, { useEffect, useRef } from "react";
import queryimg from "../../assets/images/query-img.png";
import { useQueryContext } from "../Context/QueryManagement";
import { Button } from "@mui/material";

function CustomEnqueryPop({handleClose, head, title, subtile}) {


   // Access QuerySubmitHandler from the context
   const {pagename, QuerySubmitHandler } = useQueryContext();

     // Use a reference to the form to reset it after submission
  const formRef = useRef(null);

    const handleForm = async (e) =>{
      e.preventDefault();
      const formData = new FormData(e.target);
   // Handle checkbox value manually
// Handle checkbox value manually

  // const checkbox = document.getElementById('notshow')
   // Check if the checkbox is checked and set its value accordingly
    // if (checkbox && checkbox.checked) {
    //   console.log("Checkbox is checked");
    //   formData.set('checkbox', 'yes');  // Set the value to 'yes' if checked
    // } else {
    //   formData.set('checkbox', 'no');   // Otherwise set it to 'no'
    // }
// const checkboxValue = formData.get('checkbox') === 'on' ? 'true' : 'false';
// formData.set('checkbox', checkboxValue);
      const data = Object.fromEntries(formData.entries())

      console.log(formData + "check box")
       // Call the QuerySubmitHandler with the form data
    // Call the QuerySubmitHandler with the form data
    const success = await QuerySubmitHandler(
      data.name,
      data.contact,
      data.email,
      "Preparing For UPSC CSE 2025",
      pagename, // Replace with the actual pagename if available
      window.location.href // Use current page URL for pagurl
    );

    // If submission is successful, reset the form
    if (success) {
      formRef.current.reset(); // Reset the form to clear input fields
    }
    };

  return (
    <>
    
    <div className="col-lg-12 mt-4">
                        {/* <div className="contact-form form-style-2 px-5 py-5 animated-gradient-background"> */}
                        {/* <div className="contact-form form-style-2 px-0 pt-0 py-5 animated-gradient-background1" style={{border:"1px solid #ccc"}}> */}
                            {/* <div className="section-title" style={{background:"#301C6F"}}>
                                <h4 className="title text-center text-white w-100 py-4">
                                  <h4 style={{color:"white"}}>Have A Query ?</h4>
                               
                                  
                                  </h4>
                                <p className="px-5 text-white text-center">Get A Call From Our Expert!!</p>
                            </div> */}
                            {/* <div
        className="form-wrapper rnt-contact-form rwt-dynamic-form px-5"
        id="signin"
      > */}
        <form name="form1" ref={formRef} className="rnt-contact-form rwt-dynamic-form" action="" onSubmit={handleForm} method="post">
          <div className="d-flex justify-content-center">
            {/* <img src={query} style={{ width: "60%" }} /> */}
          </div>
          <div className="form-floating my-2 ">
            <input
              type="text"
              className="form-control py-4 pb-3"
              style={{ fontSize: "14px", border: "1px solid #ccc" }}
              // style={{ fontSize: "14px", border: "1px solid rgba(255,255,255,0.2)" }}
              name="name"
              id="floatingInputname"
              placeholder="Name"
            />
            {/* <input
              type="text"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", color: "white", border: "2px solid rgba(255,255,255,0.5)", background: "transparent" }}
              name="name"
              id="floatingInputname"
              placeholder="Name"
            /> */}
            <label className="fs-5" for="floatingInputname">Name</label>
          </div>


          <div className="form-floating my-3">
            <input
              type="number"
              className="form-control py-4 pb-3"
              style={{ fontSize: "14px", border: "1px solid #ccc"}}
              // style={{ fontSize: "14px", border: "2px solid rgba(0,0,0,0.5)"}}
              name="contact"
              id="floatingInputemail"
              placeholder="Mobile No"
            />
            <label for="floatingInputemail" className="fs-5">Mobile No</label>
          </div>
          <div className="form-floating  my-3">
            <input
              type="email"
              className="form-control py-4 pb-3"
              style={{ fontSize: "14px", border: "1px solid #ccc"}}
              // style={{ fontSize: "14px", border: "2px solid rgba(0,0,0,0.5)"}}
              name="email"
              id="floatingInputnumber"
              placeholder="Email"
            />
            <label for="floatingInputnumber" className="fs-5">Email</label>
          </div>
          <span className="text-danger fs-5">* {subtile}</span>
          {/* <div className="form-check px-0">
  <input className="form-check-input" type="checkbox" name="checkbox" id="notshow"/>
  <label className="form-check-label fs-5" for="notshow">
    Don't Show Again
  </label>
</div> */}
                                              <div className="col-12 d-flex align-items-center justify-content-end">
                                              {/* <button color="btn-outlin-danger border-0 rounded-pill"> */}
  {/* <span className="bg-danger text-white fs-6 px-4 py-1 mx-2" style={{cursor: "pointer"}} onClick={()=>handleClose()}>CLOSE</span> */}
{/* </button> */}
<a className="rn-btn btn-danger btn-small me-3 p-3  py-2 text-white fs-6 submit-btn" style={{cursor: "pointer", borderRadius: "2px"}} onClick={()=>handleClose()}>CLOSE</a>
<button className="rn-btn btn-dark p-3 py-2  fs-6 submit-btn" name="submit" type="submit">SUBMIT</button>
                                              {/* <Button className="px-4 py-1 fs-6" name="submit" type="submit" variant="outlined" sx={{ mt: 0 }}>
            Submit
          </Button> */}
                                    </div>
        </form>
      {/* </div> */}
                            {/* <ul className="shape-group">
                                <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape"/></li>
                                <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape"/></li>
                            </ul> */}
                        {/* </div> */}
                    </div>
    </>
  );
}

export default CustomEnqueryPop;
