import React, { useEffect, useRef, useState } from 'react';

const ScrollToTopSVG = () => {
    const svgPathRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercent = scrollTop / docHeight;

    // Adjust the `stroke-dashoffset` based on scroll percent
    const maxDashOffset = 307.919; // Match this to your path's length
    const newDashOffset = maxDashOffset * (1 - scrollPercent);

    setScrollPosition(newDashOffset);
  };

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
      handleScroll()
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
        <div
          className={`rn-progress-parent ${isVisible ? 'rn-backto-top-active' : ''}`}
          onClick={scrollToTop}
        >
          <svg
            className="rn-back-circle svg-inner"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path
                      ref={svgPathRef}
              d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
              fill="none"
              stroke="#4e73df" // Adjust color as needed
              strokeWidth="2"
              strokeDasharray="307.919"
              strokeDashoffset={scrollPosition}
            />
          </svg>
        </div>
    </>
  );
};

export default ScrollToTopSVG;
