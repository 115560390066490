import { createContext, useState, useContext } from 'react';
import ModalComponent from '../ModalComponent/Modal';

const ModalContext = createContext();

export function ModalProvider({ children }) {
    const [open1, setOpen1] = useState(false);

      const handleOpen1 = () => setOpen1(true);
      const handleClose1 = () => setOpen1(false); 
  
    return (
      <ModalContext.Provider value={{ open1, handleOpen1, handleClose1}}>
        {children}
        <ModalComponent
        handleClose={handleClose1}
        open={open1}
        setOpen={setOpen1}
      />
      </ModalContext.Provider>
    );
  } 
  
  export function useModalContext() {
    return useContext(ModalContext);
  }