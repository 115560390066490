import React, { useEffect, useState } from "react";
import h1 from "../../assets/images/animated-svg-icons/online-class.svg"
import h2 from "../../assets/images/animated-svg-icons/instructor.svg"
import h3 from "../../assets/images/animated-svg-icons/certificate.svg"
import h4 from "../../assets/images/animated-svg-icons/user.svg"
import { useWebdetailsContext } from '../Context/WebDetails';
import ModalComponent from "../ModalComponent/Modal";
import { useModalContext } from "../Context/Modalmanagement";
import { Link } from "react-router-dom";
// import { useNewsheadlineContext } from "../Context/NewsHeadline";
// import { useToggleContext } from "../Context/Togglemanagement";



function Highlights() {
    const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
    useWebdetailsContext();

    const {
      handleOpen1
    } = useModalContext();

  useEffect(() => {
    fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
  }, []);

  const header2 = Webdetails?.header2 || "Need FREE Guidence for UPSC?";
  const title2 = Webdetails?.title2 || "Schedule a call with an expert UPSC Mentor";
  return (
    <>


<div className="features-area-2">
  <div className="container">
    <div className="features-box features-style-2">
      <div className="d-flex justify-content-between align-items-center flex-wrap py-2" style={{width: "100%"}}>
      <div className="fetaure-custom-left text-center flex-grow-1">
      <h3
            className="title"
            style={{
              fontFamily: "Spartan",
              textAlign: "center",
              color: "white",
              fontSize: "3vmax",
              verticalAlign: "middle",
            }}
          >
            {header2}
          </h3>
            <span style={{ color: "yellow", fontSize: "2vmax" }}>{title2}</span>
      </div>
      <div className="fetaure-custom-right d-flex justify-content-center align-items-center flex-grow-1 py-2">
      <Link to="#" onClick={()=>handleOpen1()} className="edu-btn btn-medium fs-5 btn-secondary">QUERY NOW <i className="icon-4"></i></Link>
      </div>
      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default Highlights