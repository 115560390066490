import { createContext, useState, useContext } from 'react';

// Create a context for managing button details
const packageContext = createContext();

export function PackageProvider({ children }) {
  // State to store button details for multiple components, keyed by component ID
  const [activeButton, setActiveButton] = useState(null); // Track active button
  const [activeButtoncourse, setActiveButtoncourse] = useState(null); // Track active button


  // Function to handle setting the active button
  const handlepackageClick = (button) => {
    setActiveButton(button); // Update active state on click
  };

  // Function to handle setting the active button
  const handlecourseClick = (button) => {
    setActiveButtoncourse(button); // Update active state on click
  };

  return (
    <packageContext.Provider
      value={{
        activeButton,
        activeButtoncourse,
        handlecourseClick,
        handlepackageClick,
      }}
    >
      {children}
    </packageContext.Provider>
  );
}

// Hook to use button details context in components
export function usePackageContext() {
  return useContext(packageContext);
}
