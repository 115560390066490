import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSeoContext } from '../Context/SEOManagement';


function SEO({seot1,seot2}) {

    const {setSEOdescription, setSEOtitle, seotitle, seodescription} = useSeoContext();

    useEffect(()=>{
        setSEOtitle(seot1)
     setSEOdescription(seot2)
    },[seot1, seot2])
  return (
    <>
     <Helmet>
        <title>{seotitle}</title>
        <meta name="description" content={seodescription} />
      </Helmet>
    </>
  )
}

export default SEO;